

import __layout_0 from '/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/verify-wallet/src/layouts/default.vue'
export const layouts = {
'404': () => import('/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/verify-wallet/src/layouts/404.vue'),
'blank': () => import('/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/verify-wallet/src/layouts/blank.vue'),
'default': __layout_0,
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
